@import "../Global.scss";

.side-navigation{
  position:fixed;
  top:100px;
  left:0;
  height: calc(100% - 100px);
  width: 80px;
  background-color: $header-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 800;

  .top, .bottom{
    display: flex;
    flex-direction: column;
  }

  .nav-link{
    cursor: pointer;
    padding:4px;
    padding-top:4px;
    text-align: center;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:80px;
    height:80px;

    color:white;
    text-decoration: none;

    svg{
      width:30px;
      height: 30px;
      fill: white;
      margin-bottom:4px;
    }
  }

  .active{
    background-color: $background;
    color: $header-color;

    svg{
      fill: $header-color;
    }
  }
}